import React from "react";

import Texts from "../../../utils/lang-utils";
import restaurantAppsImage from '../../../areas/main/assets/images/resources/restaurant-apps.jpg';
import "./Intro.scss";
import waiterAppShot from "../../../areas/main/assets/images/app-shots/waiter-app-1.png";
import kitchenAppShot from "../../../areas/main/assets/images/app-shots/kitchen-app-1.png";
import urls from "../../../areas/main/assets/urls";

const Banner = () => {


  return (
    <section className="banner-one apps-intro" id="banner">
      <div className="container">
        
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="banner-one__content">
              <h1 className="banner-one__title" dangerouslySetInnerHTML={{__html: Texts.Apps.IntroTitle}} />
              <div style={{ width: '100%'}}>

              <img className="mobile-show" src={restaurantAppsImage} alt="alter text" style={{
                        width: '100%',
                        borderRadius: 10,
                        display: 'none',
                        marginTop: '20px'
                    }} />
              </div>
              <h2 style={{ fontWeight: "normal"}} className="banner-one__text" dangerouslySetInnerHTML={{__html: Texts.Apps.IntroText}} />
              
              <a href={urls.onboarding} className="banner-one__btn thm-btn ">
                <span>{Texts.introCTA}</span>
              </a>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 mobile-hide">
            <div className="screens">
              <div className="tablet">
                <img src={kitchenAppShot} />
              </div>
              <div className="phone">
                <img src={waiterAppShot} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
